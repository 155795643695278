import React from "react";

type Props = {
  message?: string;
};

const ErrorMessage: React.FC<Props> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div className="pb-5">
      <div
        className="text-xl uppercase rounded-3xl bg-red-100 border border-red-400 text-red-700 px-4 py-3 relative"
        role="alert"
      >
        <span className="block sm:inline">{message}</span>
      </div>
    </div>
  );
};

export default ErrorMessage;
