import { useQuery } from "react-query";

import { ethers } from "ethers";
import abi from "./utils/abi.json";
let prov = ethers.getDefaultProvider("homestead");

let contract = new ethers.Contract(
  "0xe51aac67b09eaed6d3d43e794d6bae679cbe09d8",
  abi,
  prov
);
export function useCheckWhitelist(tokenId: string) {
  return useQuery<any, Error>(
    ["whitelist", tokenId],
    async () => {
      if(isNaN(parseInt(tokenId))){
        throw new Error('Enter a TokenID')
      }
      if (parseInt(tokenId) > 9999) {
        throw new Error("There are only 10K FLUFs");
      }
      let a = await contract.tokenMinted(
        "0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d",
        tokenId
      );
      return a;
    },
    {
      enabled: Boolean(tokenId),
      retry: false,
      staleTime: 60 * 60 * 1000,
    }
  );
}
